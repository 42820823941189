<template>
  <v-container class="py-1 px-0 px-sm-2 d-flex flex-grow-1 flex-column text-center justify-center align-center">
    <div class="d-none d-md-block">
      <v-tooltip bottom v-for="(item, index) in items" :key="index">
        <template #activator="{ on: onTooltip }">
          <v-btn
              @click="$router.push({ name:item})"
              text
              class="mx-1"
              v-on="{ ...onTooltip }"
              :disabled="$route.name === item"
          >
            {{ item }}
          </v-btn>
        </template>
        <span> {{ item }}</span>
      </v-tooltip>
    </div>
    <div class="d-xs-block d-md-none">
      <v-list-item-group>
        <v-list-item v-for="(item, index) in items" :key="index" @click="$router.push({ name:item})" :disabled="$route.name === item">
          <v-list-item-title>{{ item }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "TabsNavigation",
  props: {
    items: {
      type: Array
    }
  },
  data: () => ({
    localItems: [],
    more: [],
  }),
  methods: {
    addItem(item) {
      const removed = this.items.splice(0, 1)
      this.items.push(
          ...this.more.splice(this.more.indexOf(item), 1),
      )
      this.more.push(...removed)
    },
  },
  mounted() {
    this.localItems = this.items
  }
}
</script>

<style scoped>

</style>