import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[(_vm.activePerson)?_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"4","md":"3"}},[_c(VAvatar,{attrs:{"color":"indigo","size":"63"}},[_c(VIcon,{attrs:{"dark":""}},[_vm._v(" mdi-account-circle ")])],1)],1),_c(VCol,{attrs:{"cols":"12","sm":"4","md":"6"}},[_vm._v(" "+_vm._s(_vm.activePerson.name)+" ")]),_c(VCol,{attrs:{"cols":"12","sm":"4","md":"3"}},[_vm._v(" Buttons ")])],1):_vm._e(),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('tabs-navigation',{attrs:{"items":_vm.items}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"8","md":"9"}},[_c(VSheet,{staticClass:"surface",attrs:{"min-height":"70vh","rounded":"lg"}},[_c('router-view')],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }