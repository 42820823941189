<template>
  <v-container>
    <v-row v-if="activePerson">
      <v-col cols="12" sm="4" md="3">
        <v-avatar color="indigo" size="63">
          <v-icon dark>
            mdi-account-circle
          </v-icon>
        </v-avatar>
      </v-col>
      <v-col cols="12" sm="4" md="6">
        {{activePerson.name}}
      </v-col>
      <v-col cols="12" sm="4" md="3">
        Buttons
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <tabs-navigation :items="items" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="8" md="9">
        <v-sheet
            min-height="70vh"
            rounded="lg"
            class="surface"
        >
          <router-view />
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapState} from "vuex";
import TabsNavigation from "@/components/shared/TabsNavigation";

export default {
  name: "Person",
  components: {TabsNavigation},
  computed: {
    ...mapState("person",["activePerson"])
  },
  data() {
    return {
      items: ['About','Licences','Medicals','Other Non-Licence Qualifications and Currency','Settings and Preferences']
    }
  },
  methods:{
    ...mapActions("person",["setActivePerson"])
  },
  async mounted(){
    await this.setActivePerson(this.$route.params.id)
  },
  watch: {
    '$route.params.id': {
      handler: async function (id) {
        await this.setActivePerson(id)
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style scoped>

</style>